﻿/* ------------------------------
	// Country selector
	------------------------------ */


.content-countrylangcurrency-selector { 
    cursor:pointer;
    & img.country-img {
        max-width: 25px;
        min-width:23px;
        display: block;
        margin-left:auto;
        margin-right:auto;
    }
}
/* country selector in mobile menu*/
.mmenu-push-right .content-countrylangcurrency-selector {
    display: block;
    padding: 1em;
    color: #333;

    &:before {
        margin-right: 0.5em;
    }

    & .country-img{
        display:inline-block;
        margin-right:11px;
    }
}



/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

#content-language-selector {
    & ul li {
        list-style: none;
        display: inline-block;
        margin-right: 10px;
        border-style: solid;
        border-radius: 50%;
        width: 41px;
        height: 41px;
        padding-top: 7px;
        border-width: 2px;
        border-color: transparent;
        cursor: pointer;

        &:hover {
            border-color: #f2f1f1;
        }
    }

    & ul li.active {
        border-style: solid;
        border-radius: 50%;
        width: 41px;
        height: 41px;
        padding-top: 7px;
        border-width: 2px;
        border-color: #f2f1f1;
        background-color: #f2f1f1;
    }

    & ul li.selected {
        border-style: solid;
        border-radius: 50%;
        width: 41px;
        height: 41px;
        padding-top: 7px;
        border-width: 2px;
        border-color: #f2f1f1;
        background-color: none;
    }

    & ul li.selected.active {
        border-color: transparent;
    }

    & img.country-img {
        width: 25px;        
    }
}

span.country-lang-info {
    width: 100%;
    display: block;
    background-color: #f2f1f1;
    padding: 5px;
    margin-bottom: 10px;

    &:before {
        content: "\e80d";
        display: inline-block;
        font-style: normal;
        font-variant-ligatures: normal;
        font-variant-caps: normal;
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1;
        font-family: multicase-icons;
        font-size: 20px;
        color: #555;
        min-width: 30px;
        text-align: center
    }
}



#content-language-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align:center;
}
.country-selector-buttons {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    & button{
        width:125px;
    }
    margin:10px;
}

.country-lang-currency-container{
    display:flex;
    justify-content:flex-end;
}

#content-countrylangcurrency {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    width: 242px;
    text-align: center;


    & .dropdown {
        width: 240px;

        & button {
            width: 240px;
        }

        & .dropdown-menu {
            width: 240px;
        }
    }

    & .dropdown li {
        cursor: pointer;
        margin:5px;
    }

    & .dropdown li img.country-img {
        margin-left: 5px;
        max-width: 25px;
        display: inline-block;
        vertical-align:top;
    }

    & a {
        display: inline-block;
        width: 178px;
    }

    & .dropdown .currency-dropdown span {
        width: 200px;
    }

    & .dropdown .country-dropdown span {
        width: 200px;
    }

    & .dropdown.open ul {
        max-height: 300px;
        overflow-y: scroll;
        max-width: 260px;
        overflow-x: hidden;

        & li {
            max-width: 240px;
        }
    }
}

.country-selector-popup .modal-lg {
    & .modal-content {
        margin: 10px;
    }
}

    /*==========  Mobile First Method  ==========*/
    /* Custom, iPhone Retina */
    @media only screen and (min-width : 320px) {
    }
    /* Extra Small Devices, Phones */
    @media only screen and (min-width : 480px) {
    }

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
    .country-selector-popup .modal-dialog.modal-lg {
        height: 320px;
        overflow-y: visible;

        & .modal-content {
            overflow: visible;
        }
    }
}

@media only screen and (max-width: 767px) {

    .header-main .small-cart-onpage.country-lang-currency-container .content-countrylangcurrency-selector {
        display:none;
    }

    @media only screen and (min-width : 480px) {
        .modal-dialog {
            width: 95%;
            max-width:600px;
            height: 320px;
            padding: 0;
            margin: 0;
        }

        .modal-content {
            height: 100%;
            border-radius: 0;
            /*color:white;*/
            overflow: visible;
        }
    }
}
    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {

        @media only screen and (max-width : 992px) {
            .country-selector-popup .modal-dialog {
                width: 90%;
                max-width:600px;
                padding-left: 15px;
                padding-right: 25px;
            }
        }

       
    }
    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
        .country-lang-currency-view {
            width: 100%;
            position: relative;
            padding: 10px;
            padding-right: 15px;
        }

        .country-selector-popup .modal-lg {
            width: 500px;
        }


       

    .content-countrylangcurrency-selector {
        
        display: block;
        margin-top: 9px;
    }

    
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/
